<template lang="pug">
v-snackbar.pa-0.pb-4(
  v-model="$store.state.searchSheet",
  transition="slide-y-reverse-transition",
  timeout="-1",
  outlined,
  fixed,
  light,
  width="100%"
)
  v-text-field.pl-2(
    v-model="$store.state.search",
    prepend-icon="mdi-magnify",
    append-outer-icon="mdi-chevron-down",
    outlined,
    dense,
    hide-details,
    clearable,
    placeholder="Search",
    autofocus,
    @click:append-outer="close()",
    @click:clear="close()"
  ) 
</template>
<script>
import { VSnackbar } from "vuetify/lib";
export default {
  name: "search",
  components: { VSnackbar },
  methods: {
    close() {
      this.$store.state.searchSheet = false;
    },
  },
};
</script>
<style scoped>
.v-snack__wrapper {
  margin: 0px;
}
.v-snack__content {
  padding: 8px 0px 8px 0px;
}
</style>