<template lang="pug">
v-snackbar.pa-0.pb-4(
  v-model="show",
  transition="slide-y-reverse-transition",
  timeout="-1",
  outlined,
  fixed,
  light,
  width="100%",
  middle
)
  v-toolbar.pa-0(flat)
    v-chip(small, color="secondary")
      strong {{ $store.state.selectedItemsGroup.length }}
    v-spacer 
    v-btn(
      icon,
      color="black",
      @click.stop="$emit('delete', $store.state.selectedItemsGroup)"
    )
      v-icon mdi-delete
    v-spacer 
    v-btn(
      icon,
      color="secondary",
      @click.stop="$emit('pin', $store.state.selectedItemsGroup)"
    )
      v-icon.tilted mdi-pin
    v-spacer 
    v-btn(icon, @click.stop="$emit('unpin', $store.state.selectedItemsGroup)")
      v-icon.tilted mdi-pin-off
    v-spacer
    v-icon(color="primary", @click.stop="$store.state.selectedItemsGroup = []") mdi-close
</template>
<script>
import { VSnackbar } from "vuetify/lib";
export default {
  name: "search",
  components: { VSnackbar },
  data: () => ({
    show: true,
  }),
  methods: {},
};
</script>
<style>
.v-snack__wrapper {
  margin: 0px;
}
.v-snack__content {
  padding: 0px 0px 0px 6px;
}
.tilted {
  transform: rotate(-45deg);
}
</style>