import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        //https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=01579B&secondary.color=FF9100
        themes: {
            light: {
                primary: "#01579b",
                secondary: "#ff9100",
                accent: colors.shades.blue,
                error: colors.red.base,
                warning: colors.yellow.base,
                info: colors.blue.base,
                success: colors.lightGreen.base,
                background: colors.grey.lighten5, // Not automatically applied
            },
            dark: {
                primary: "#4f83cc",
                secondary: "#ffc246",
                accent: colors.shades.blue,
                error: colors.red.base,
                warning: colors.yellow.base,
                info: colors.blue.base,
                success: colors.lightGreen.base,
                background: colors.grey.lighten5, // Not automatically applied
            },
        },
    },
});