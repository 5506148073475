<template lang="pug">
.home
  v-app-bar.rounded-b-lg(
    dense,
    app,
    dark,
    color="primary",
    max-height="50",
    elevation="0"
  ) 
    v-toolbar-title.pl-0(color="primary") 
      span(v-if="config.environment", style="background-color: maroon") {{ config.environment }} Pure {{ '&' }} Fresh
      span(v-else) Pure {{ '&' }} Fresh
    v-spacer
    v-btn(icon, @click="$router.push('/profile')")
      v-icon mdi-account-settings
  span.pl-1(v-show="$store.state.pdfPercent") {{ $store.state.pdfPercent }}%
  v-progress-linear(
    v-show="$store.state.pdfPercent",
    :value="$store.state.pefPercent"
  )
  span.pl-1(v-show="$store.state.expPercent") {{ $store.state.expPercent }}%
  v-progress-linear(
    v-show="$store.state.expPercent",
    :value="$store.state.expPercent"
  ) 
  span.pl-1(v-show="$store.state.rstPercent") {{ $store.state.rstPercent }}%
  v-progress-linear(
    v-show="$store.state.rstPercent",
    :value="$store.state.rstPercent"
  ) 
  v-toolbar(flat, color="rgba(0, 0, 0, 0)", dense) 
    v-toolbar-title {{ $store.state.search ? 'Search Results' : 'My Items' }}({{ filteredItems.length }})
    v-spacer.pl-0
    v-chip(
      v-if="$store.state.search",
      small,
      color="secondary",
      @click.stop="$store.state.search = ''; $store.state.searchSheet = false"
    ) 
      v-icon(left, small) mdi-filter-remove
      span CLEAR
    v-menu(v-model="showMenu")
      template(v-slot:activator="{ on }")
        v-btn(v-on="on", icon)
          v-icon(color="secondary") mdi-dots-vertical
      v-list 
        v-list-item
          v-list-item-title(@click="$router.go()")
            v-icon(color="secondary", left) mdi-refresh
            span Refresh
        v-list-item(:disabled="$store.state.items.length == 0")
          v-list-item-title(@click="exportToPDF()")
            v-icon(color="secondary", left) mdi-file-pdf-box-outline
            span Export as PDF
        v-list-item(:disabled="$store.state.items.length == 0")
          v-list-item-title(@click="emailData()")
            v-icon(color="secondary", left) mdi-email-outline
            span Send via Email
        v-list-item(:disabled="$store.state.items.length == 0")
          v-list-item-title(@click="exportData()")
            v-icon(color="secondary", left) mdi-file-export-outline
            span Export as Data
        v-list-item
          v-list-item-title(@click="$refs.gallery.click()")
            v-icon(color="secondary", left) mdi-file-import-outline
            span Import from Data

  .rounded-xl.items.pl-1.pr-1(
    v-if="$store.state.items.length && !$store.state.wait"
  )
    lazy-img(
      v-for="item in filteredItems",
      :key="item._id",
      :item="item",
      @view="viewItem(item, $event)"
    )
  .rounded-xl.items.pl-4.pr-4.d-flex.flex-column.align-center(
    v-if="($store.state.items.length == 0) & !$store.state.wait"
  )
    v-sheet.pt-4(align="center")
      v-icon(color="secondary") mdi-alert
      span No items found.
      br
      br
      br
      v-btn(fab, color="secondary", @click="launchCamera()")
        v-icon(color="black") mdi-camera-plus-outline
      br
      span Tap here to scan an item!
      br
      br
      br

  v-sheet.pa-4(v-if="$store.state.items.length == 1", align="center") 
    span Congratulations!! Your first item is in.
    br
    span Keep going with adding more items.
  v-sheet.pa-4(v-if="$store.state.items.length == 1", align="center") 
    br
    br
    br
    v-btn(
      small,
      dense,
      href="https://ezitemsholder.penzigo.net/guide",
      target="_blank"
    )
      span Learn More..
      v-icon(color="secondary", small, style="transform: rotate(-0deg)") mdi-open-in-new
  span(v-show="false") {{ $store.state.ocrMsg }}
  v-fab-transition
    v-btn(
      v-if="$store.state.items.length > 1",
      color="secondary",
      @click="launchSearch()",
      fixed,
      bottom,
      left,
      fab,
      app
    )
      v-badge(v-if="$store.state.search", color="error", dot, top, right)
        v-icon(color="black") mdi-magnify
      v-icon(color="black", v-else) mdi-magnify
  v-fab-transition(v-if="$store.state.items.length != 0 || userType != null")
    v-btn(
      color="secondary",
      @click="launchCamera()",
      fixed,
      bottom,
      right,
      fab,
      app
    )
      v-icon(color="black") mdi-camera-plus-outline
  camera-sheet(
    width="512",
    height="512",
    aspect="1",
    ref="camera",
    v-if="$store.state.cameraSheet",
    @data="addItem($event)"
  )
  search-bar
  group-action-bar(
    v-if="$store.state.selectedItemsGroup.length",
    @delete="deleteItems($event)",
    @pin="pinItems($event)",
    @unpin="unpinItems($event)"
  ) 
  item-dialog(ref="item", v-if="$store.state.itemDialog")
  confirm-dialog(ref="confirm")
  input(
    v-show="false",
    type="file",
    ref="gallery",
    accept="*",
    v-on:change="importData($event)"
  )
  a(
    v-show="false",
    ref="emailbtn",
    :href="'mailto:email?subject=Data%20from%20EzHolder%20for%20Business%20Cards&body=Dear%20friend%2C%0A%0A%0APlease%20find%20attached%20the%20data%20file%20exported%20from%20EzHolder%20for%20Business%20Cards%20app.%0AYou%20can%20follow%20the%20below%20steps%20to%20import%20into%20your%20app.%20If%20you%20have not%20installed%20the%20app%2C%20please%20do%20so%20by%20%20searching%20for%20the%20app%20in%20play%20store%20or%20by%20visiting%20the%20site%20https%3A%2F%2Fezcardsholder.penzigo.net%0A%0A%0ASteps%3A%0A%0A%0A0.%20Download%20the%20attachment%20in%20this%20email%20to%20your%20device.%0A1.%20From%20My%20Cards%20screen%2C%20access%20the%20import%20data%20menu%20by%20tapping%20the%20expansion%20menu%20on%20the%20My%20Cards%20toolbar.%0A2.%20Select%20the%20data%20file%20from%20the%20downloads%20folder%2C%20and%20thats%20it.%20You%20will%20have%20all%20the%20cards%20imported%20in%20your%20app%20in%20the%20device.%0A%0A%0ACheers%2C%0AYour%20friend%0A%3Cplease%20attach%20the%20data%20file%20' + attachment + '%20%3E'"
  )
</template>

<script>
import { jsPDF } from "jspdf";
import CameraSheet from "./sheets/Camera.vue";
import SearchBar from "./bars/Search.vue";
import GroupActionBar from "./bars/GroupAction.vue";
import ItemDialog from "./dialogs/Item.vue";
import LazyImg from "./components/LazyImg.vue";
import { config } from "../config.js";
import ConfirmDialog from "./dialogs/Confirm.vue";

export default {
  name: "Home",
  components: {
    CameraSheet,
    SearchBar,
    ItemDialog,
    LazyImg,
    ConfirmDialog,
    GroupActionBar,
  },
  async beforeRouteLeave(to, from, next) {
    let allowNext = true;
    if (this.showMenu) {
      this.showMenu = false;
      allowNext = false;
    } else if (this.$store.state.cameraSheet) {
      const camera = this.$refs.camera;
      if (camera.tab == 1) {
        camera.flipVideo();
      } else {
        camera.close();
      }
      allowNext = false;
    } else if (this.$store.state.itemDialog) {
      if (this.$refs.item.helpMode) {
        this.$refs.item.helpMode = false;
      } else if (this.$refs.item.editMode) {
        this.$refs.item.cancelEdit();
      } else if (
        this.$refs.item.clipMode &&
        this.$store.state.selectedItem &&
        !this.$store.state.selectedItem.inspecting
      ) {
        this.$refs.item.clipMode = false;
      } else {
        this.$refs.item.goBack();
        if (this.$store.state.search) {
          //IMP:restore back the search which was closed in viewItem()
          this.$store.state.searchSheet = true;
        }
      }
      allowNext = false;
    } else if (this.$store.state.selectedItemsGroup.length) {
      this.$store.state.selectedItemsGroup = [];
      allowNext = false;
    } else if (this.$store.state.search && true) {
      this.$store.state.search = "";
      allowNext = false;
    } else if (this.$store.state.searchSheet) {
      this.$store.state.searchSheet = false;
      allowNext = false;
    }
    if (allowNext && to.path == "/") {
      const dbg = this.$store.state.dbgdmp;
      // if ocr = 1 && any one of the actions and length more than 5
      const requestReview =
        this.$store.state.items.length > 5 &&
        dbg.i2t.ext == 1 &&
        (dbg.act.phn == 1 ||
          dbg.act.eml == 1 ||
          dbg.act.txt == 1 ||
          dbg.act.whp == 1 ||
          dbg.act.lnk == 1 ||
          dbg.act.src == 1);
      if (requestReview && this.$store.state.dbgdmp.rvw.ok != 1) {
        // deviceutils.requestReview(this.$store);
        allowNext = false;
      } else {
        if (
          await this.$refs.confirm.open("Exit App", "Are you sure?", {
            color: "primary",
          })
        ) {
          // deviceutils.exitApp();
        }
      }
    }
    next(allowNext);
  },
  data: () => ({
    config: config,
    showMenu: false,
    userType: null,
    attachment: "ezitemsholder/temp/ezitems_data.dat",
  }),
  computed: {
    filteredItems() {
      if (!this.$store.state.search) {
        return this.$store.state.items;
      } else {
        let results = [];
        if (this.$store.state.search === "*") {
          results = this.$store.state.items.filter((c) => c.isPinned);
        } else {
          results = this.$store.state.items.filter((c) => {
            if (c.text && c.text.trim()) {
              const text = c.text.toLowerCase();
              const searchText =
                this.$store.state.search !== 0
                  ? this.$store.state.search.toLowerCase()
                  : this.search;
              let found = true;
              const searchTerms = searchText.split(",");
              searchTerms.forEach((term) => {
                term = term ? term.trim() : false;
                found = term ? found && text.indexOf(term) > -1 : found;
              });
              return found;
            } else {
              return true;
            }
          });
        }
        return results;
      }
    },
    itemDialog() {
      return this.$store.state.itemDialog;
    },
  },
  methods: {
    getDefaultItemModel() {
      return {
        inspecting: false,
        createdTS: new Date().getTime(),
        updatedTS: new Date().getTime(),
        usedTS: new Date().getTime(),
        userKey: "x",
        text: "",
        freshness: Math.floor(Math.random() * 3),
        bodyRect: null,
        gillRect: null,
        eyeRect: null,
        _attachments: {
          bodyImage: {
            content_type: "image/png",
            data: this.getDummyImageBlob(),
          },
          gillImage: {
            content_type: "image/png",
            data: this.getDummyImageBlob(),
          },
          eyeImage: {
            content_type: "image/png",
            data: this.getDummyImageBlob(),
          },
        },
      };
    },
    launchCamera() {
      this.$store.state.cameraPicType = "body";
      this.$store.state.cameraSheet = true;
    },
    launchSearch() {
      this.$store.state.searchSheet = true;
    },
    addItem(data) {
      const picType = this.$store.state.cameraPicType;
      let item = this.$store.state.selectedItem;
      if (picType == "body") {
        item = this.getDefaultItemModel(); //JSON.parse(JSON.stringify(this.itemModel));
        const dummy = this.getDummyImageBlob();
        item._attachments.bodyImage.data = dummy;
        item._attachments.gillImage.data = dummy;
        item._attachments.eyeImage.data = dummy;
      }
      item._attachments[picType + "Image"].data = this.dataURItoBlob(data);
      this.$store.dispatch("saveItem", item);
      if (picType == "body" && this.$store.state.showActionAfterSave) {
        this.viewItem(item);
      } else {
        this.$refs.item.initImages();
        this.$refs.item.render(picType);
      }
    },
    viewItem(item, event) {
      this.srcElement = event && event.srcElement;
      this.$store.state.searchSheet = false;
      this.$store.state.selectedItem = item;
      this.$store.state.itemDialog = true;
    },
    scrollToSelectedItem() {
      if (this.srcElement) {
        this.$vuetify.goTo(this.srcElement, {
          duration: 1000,
          offset: 110,
          easing: "easeOutCubic",
        });
      }
    },
    async exportToPDF() {
      this.$store.state.wait = true;
      const size = this.filteredItems.length;
      let idx = 0;
      const rowsPerPage = 4;
      const colsPerRow = 3;
      const pages = Math.ceil(size / 12);
      const cols = size;
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [297, 210],
      });
      for (let page = 0; page < pages; page++) {
        doc.setPage(page + 1);
        doc.setFontSize(9);
        doc.setTextColor(200);
        for (let row = 0; row < rowsPerPage; row++) {
          for (let col = 0; col < colsPerRow; col++) {
            const currentCol =
              page * rowsPerPage * colsPerRow + row * colsPerRow + col;
            if (cols > currentCol) {
              doc.text(
                15 + col * 94,
                4 + row * 51,
                "Exported from Pure and Fresh Android App",
                null,
                0
              );
              //load the entire item
              const item = await this.$store.dispatch(
                "loadItemSync",
                this.filteredItems[currentCol]._id
              );
              const objURL = URL.createObjectURL(
                item._attachments.bodyImage.data
              );
              doc.addImage(objURL, "PNG", 15 + col * 94, 6 + row * 51, 80, 45);
              //revoke URL since it would have been loaded already.
              URL.revokeObjectURL(objURL);
              idx++;
              this.$store.state.pdfPercent = parseInt((100 * idx) / size);
            }
          }
        }

        if (page < pages - 1) {
          //avoid last extra add since it addes by default in the begining
          doc.addPage();
        }
      }
      this.$store.state.dbgdmp.pdf.ok = 1;
      // await deviceutils.writePDF(doc.output("blob"), this.$store);
      this.$store.state.pdfPercent = 0;
    },
    exportData() {
      const fileName =
        "ezitemsholder/data/ezitems_data_" + new Date().getTime() + ".dat";
      this.$store.dispatch("exportItems", {
        items: this.filteredItems,
        fileName,
      });
    },
    emailData() {
      const fileName = this.attachment;
      this.$store.dispatch("exportItems", {
        items: this.filteredItems,
        fileName,
      });
      setTimeout(() => {
        this.$refs.emailbtn.click();
      }, 1000);
    },
    importData({
      target: {
        files: [file],
      },
    }) {
      this.$refs.gallery.value = null;
      this.$store.dispatch("restoreDB", file);
    },
    async deleteItems(items) {
      if (
        await this.$refs.confirm.open(
          "Delete",
          "Are you sure you want to delete the selected item(s)?",
          {
            color: "primary",
          }
        )
      ) {
        const payload = { action: "delete", items: items };
        this.$store.dispatch("updateItems", payload);
      }
    },
    async pinItems(items) {
      const payload = { action: "pin", items: items };
      this.$store.dispatch("updateItems", payload);
    },
    async unpinItems(items) {
      const payload = { action: "unpin", items: items };
      this.$store.dispatch("updateItems", payload);
    },
  },
  watch: {
    itemDialog(nVal, oVal) {
      if (oVal && !nVal) {
        this.scrollToSelectedItem();
      }
    },
  },
};
</script>
<style>
.home {
  margin-top: 0px;
}
.items {
  display: grid;
  grid-template-columns: repeat(2, 49.5%);
  max-width: 640px;
  grid-template-rows: auto;
  grid-gap: 4px 4px;
}
.v-chip:not(.v-chip--outlined).primary,
.v-chip:not(.v-chip--outlined).secondary,
.v-chip:not(.v-chip--outlined).accent,
.v-chip:not(.v-chip--outlined).success,
.v-chip:not(.v-chip--outlined).error,
.v-chip:not(.v-chip--outlined).warning,
.v-chip:not(.v-chip--outlined).info {
  color: black;
}
</style>