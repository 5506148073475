import Vue from 'vue'
import Vuex from 'vuex'
import PouchDB from 'pouchdb-browser'
// import LZString from 'lz-string'
import { config } from '../config.js';

function initDB() {
  return new PouchDB('purenfreshdb', { revs_limit: 1, auto_compaction: true });
}

// function download(data, filename, type = "text/plain") {
//   const file = new Blob([data], { type: type });
//   if (window.navigator.msSaveOrOpenBlob) // IE10+
//     window.navigator.msSaveOrOpenBlob(file, filename);
//   else { // Others
//     const a = document.createElement("a"),
//       url = URL.createObjectURL(file);
//     a.href = url;
//     a.download = filename;
//     document.body.appendChild(a);
//     a.click();
//     setTimeout(function () {
//       document.body.removeChild(a);
//       window.URL.revokeObjectURL(url);
//     }, 0);
//   }
// }

let db = initDB();

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    wait: false,
    status: false,
    error: false,
    message: "",
    bkpPercent: 0,
    rstPercent: 0,
    pdfPercent: 0,
    expPercent: 0,
    cameraSheet: false,
    cameraPicType: "body", //gill and eye
    searchSheet: false,
    itemDialog: false,
    items: [],
    selectedItemsGroup: [],
    selectedItem: null,
    dbSize: '',
    showActionAfterSave: true,
    continueCameraAfterSave: false,
    debugEnabled: config.debugEnabled,
    allowNewEdits: false,
    dbgdmp: {
      i2t: {
        ok: -1,
        t0: -1,
        ext: -1,
        t1: -1,
        err: ''
      },
      db: {
        ok: -1,
        rc: -1,
        sz: 0,
        err: ''
      },
      cam: {
        ok: -1,
        err: ''
      },
      dsc: {
        ok: -1, //*
        err: ''//*
      },
      dvc: {
        info: null,
        scrw: -1,
        scrh: -1
      },
      act: {
        phn: -1,
        eml: -1,
        txt: -1,
        whp: -1,
        lnk: -1,
        src: -1
      },
      pdf: {
        ok: -1,
        err: ''
      },
      ads: {
        ok: -1,
        err: ''
      },
      rvw: {
        ok: -1,
        err: ''
      }
    }
  },
  mutations: {
  },
  actions: {
    saveItem({ state }, item) {
      state.wait = true;
      item.updatedTS = new Date().getTime();
      let save = db.put;
      const isNew = item._id ? false : true;
      item.text = item.text ? item.text.trim() : '';//trim before save

      if (isNew) {
        item.createdTS = new Date().getTime();
        save = db.post
      }
      save(item).then((response) => {
        //{ "ok": true, "id": "734c3e34-74d8-4dc1-9c5b-6b28daf5e51c", "rev": "1-2f5a44d9b4ffd3640f54051c1d21e481" }
        db.get(response.id, { attachments: true, binary: true }).then((doc) => {
          if (state.selectedItem == null || !state.selectedItem._id || state.selectedItem._id == doc._id) {
            state.selectedItem = doc
          }
          if (isNew) {
            state.items.push(doc);
          } else {
            const idx = state.items.findIndex(c => {
              return c._id == doc._id
            })
            if (idx != -1) {
              state.items[idx] = doc
            }
          }
          state.items.sort((c1, c2) => {
            return c2.updatedTS - c1.updatedTS
          }).sort((c1, c2) => {
            return !!c2.isPinned - !!c1.isPinned
          })
          state.wait = false;
        }).catch((err) => {
          state.dbgdmp.db.err += err;
          // console.log(err);
          state.wait = false;
        });
      }).catch((err) => {
        state.dbgdmp.db.err += err;
        // alert(err);
        state.wait = false;
      });
    },
    loadItems({ state }) {
      state.wait = true;
      db.allDocs({ include_docs: true, attachments: false, binary: false, descending: true })
        .then(function (doc) {
          state.dbgdmp.db.ok = 1;
          state.items = []
          doc.rows.forEach(row => {
            const item = row.doc;
            state.items.push(item)
          });
          state.dbgdmp.db.rc = state.items.length;
          state.items.sort((c1, c2) => {
            return c2.updatedTS - c1.updatedTS
          }).sort((c1, c2) => {
            return !!c2.isPinned - !!c1.isPinned
          })
          state.wait = false;
        }).catch(function (err) {
          state.wait = false;
          state.dbgdmp.db.err += "Error loading all docs. " + err;
        });
    },
    async slurpAttachment(_, { item, image }) {
      try {
        const img = await db.getAttachment(item._id, image)
        return img;
      } catch (err) {
        // console.log(err);
        return err;
      }
    },
    async loadItemSync({ state }, itemId) {
      try {
        return await db.get(itemId, { attachments: true, binary: true })
      } catch (err) {
        state.message = "Error reading item. " + err;
        state.error = true;
      }
    },
    async removeItem({ state }, doc) {
      try {
        var response = await db.remove(doc);
        if (response.ok) {
          const idx = state.items.findIndex(c => {
            return c._id == doc._id
          })
          if (idx != -1) {
            state.items.splice(idx, 1)
          }
          state.message = "Item deleted successfully."
          state.status = true
        }
      } catch (err) {
        state.message = "Error in deleting the item. " + err;
        state.error = true;
        state.dbgdmp.db.err += err
        // console.log(err);
      }
    },
    async updateItems({ dispatch, state }, { action, items }) {
      state.wait = true;
      let task;
      if (action == "delete") {
        items.forEach(d => d._deleted = true);
        task = "Deleted";
      } else if (action == "pin") {
        items.forEach(d => d.isPinned = true);
        task = "Pinned";
      } else if (action == "unpin") {
        items.forEach(d => d.isPinned = false);
        task = "Unipinned";
      }
      try {
        await db.bulkDocs(items)
        state.wait = false;
        state.message = ` ${task} ${items.length} items successfully`;
        state.status = true;
        state.selectedItemsGroup = [];
        dispatch('loadItems')
      } catch (err) {
        state.wait = false;
        state.message = `Error executing ${action} action.[${err}]`;
        state.error = true;
      }
    },
    async dbInfo({ state }) {
      const info = await navigator.storage.estimate();
      state.dbSize = parseFloat(info.usage / 1024 / 1024).toFixed(2);
    },
    compactDB({ state }) {
      state.wait = true;
      db.compact().then(function (result) {
        state.message = result && result.ok ? "DB conpacted" : "DB did not compact";
        state.status = true;
        state.wait = false;
      }).catch(function (err) {
        const errMsg = "Error while compacting." + JSON.stringify(err);
        state.dbgdmp.db.err += errMsg;
        state.message = errMsg
        state.error = true;
        state.wait = false;
      });
    },
    backupDB({ state }) {
      alert('TODO:backupdb' + state.wait);
      // state.wait = true;
      // const bkpFileName = "purenfresh/backups/purenfresh_backup_" + new Date().getTime() + ".dat";
      // const info = await deviceutils.getDeviceInfo();
      // window.localStorage.bkpfile = bkpFileName;
      // const lines = [];

      // db.allDocs({ include_docs: false, attachments: false })
      //   .then(async (result) => {
      //     const total = result.rows.length;
      //     for (let i = 0; i < total; i++) {
      //       const r = result.rows[i];
      //       const itemId = r.id;
      //       const item = await db.get(itemId, { attachments: true });
      //       state.bkpPercent = parseInt(100 * (i + 1) / total);
      //       const bodyImage = item._attachments.bodyImage.data;
      //       item._attachments.bodyImage.data = null;
      //       const lineJSON = { a: LZString.compress(JSON.stringify(item)), b: bodyImage };
      //       const line = JSON.stringify(lineJSON);
      //       if (info && ['android', 'ios'].includes(info.platform)) {
      //         if (i < total - 1) {
      //           await deviceutils.appendFile({ path: bkpFileName, data: line + "\n" });
      //         } else if (i == total - 1) {
      //           await deviceutils.appendFile({ path: bkpFileName, data: line }); //last line no new line
      //           const notif = {
      //             title: "Backup Completed",
      //             body: "Backup file available at ~/Documents/" + bkpFileName,
      //             attachments: [],
      //             id: 1,
      //             schedule: { at: new Date(Date.now() + 1000 * 3) },
      //             sound: null,
      //             actionTypeId: "",
      //             extra: null
      //           }
      //           deviceutils.postNotification(notif);
      //           state.wait = false;
      //           state.message = "Cards backed up successfully";
      //           state.status = true;
      //         }
      //       } else {
      //         lines.push(line);
      //         if (i == total - 1) {
      //           download(
      //             lines.join("\n"),
      //             bkpFileName,
      //             'text/plain'
      //           );
      //           state.wait = false;
      //           state.message = "Cards backed up successfully";
      //           state.status = true;
      //         }
      //       }
      //     }
      //   }).catch(function (error) {
      //     state.wait = false;
      //     state.message = "Error while backing up. " + JSON.stringify(error);
      //     state.error = true;
      //     state.dbgdmp.db.err += state.error;
      //     state.dbgdmp.db.err += state.message;
      //   });
    },
    exportItems({ state }, { items, fileName }) {
      alert('TODO Exportitems' + state.wait + items.length + fileName)
      // state.wait = true;
      // const lines = [];
      // const info = await deviceutils.getDeviceInfo();
      // const keys = cards.map(c => c._id);
      // await deviceutils.deleteFile(fileName);
      // db.allDocs({ keys: keys, include_docs: false, attachments: false })
      //   .then(async (result) => {
      //     const total = result.rows.length;
      //     for (let i = 0; i < total; i++) {
      //       const r = result.rows[i];
      //       const cardId = r.id;
      //       const card = await db.get(cardId, { attachments: true });
      //       state.expPercent = parseInt(100 * (i + 1) / total);
      //       const bodyImage = card._attachments.bodyImage.data;
      //       card._attachments.bodyImage.data = null;
      //       const lineJSON = { a: LZString.compress(JSON.stringify(card)), b: bodyImage };
      //       const line = JSON.stringify(lineJSON);
      //       if (info && ['android', 'ios'].includes(info.platform)) {
      //         if (i < total - 1) {
      //           await deviceutils.appendFile({ path: fileName, data: line + "\n" });
      //         } else if (i == total - 1) {
      //           await deviceutils.appendFile({ path: fileName, data: line }); //last line no new line
      //           state.wait = false;
      //           state.message = "Cards exported successfully";
      //           state.status = true;
      //         }
      //       } else {
      //         lines.push(line);
      //         if (i == total - 1) {
      //           download(
      //             lines.join("\n"),
      //             fileName,
      //             'text/plain'
      //           );
      //           state.wait = false;
      //           state.message = "Cards exported successfully";
      //           state.status = true;
      //         }
      //       }
      //     }
      //     state.expPercent = 0;
      //   }).catch(function (error) {
      //     state.wait = false;
      //     state.message = "Error while exporting data. " + JSON.stringify(error);
      //     state.error = true;
      //   });
    },
    restoreDB({ state, }, bkpFile) {
      alert("TODO: restoreDB" + state.wait + bkpFile)
      // if (bkpFile) {
      //   state.wait = true
      //   const reader = new FileReader();
      //   reader.onload = (async ({ target: { result } }) => {
      //     const lines = result.split('\n')
      //     const total = lines.length;
      //     let cardsCount = 0;
      //     let conflictsCount = 0;
      //     let save = db.put;
      //     for (let i = 0; i < lines.length; i++) {
      //       const line = lines[i];
      //       let card;
      //       try {
      //         const lineJSON = JSON.parse(line);
      //         card = JSON.parse(LZString.decompress(lineJSON.a));
      //         card._attachments.bodyImage.data = lineJSON.b;
      //       } catch (err) {
      //         state.wait = false;
      //         state.message = `Error loading backup file. Backup [${bkpFile.name}] seems to be corrupt. [${err}]`;
      //         state.error = true;
      //         break;
      //       }
      //       if (state.allowNewEdits) {
      //         delete card._id;
      //         save = db.post
      //       }
      //       try {
      //         await save(card);
      //         cardsCount++;
      //       } catch (err) {
      //         conflictsCount++;
      //       }
      //       state.rstPercent = parseInt(100 * (i + 1) / total);
      //     }
      //     state.rstPercent = 0;
      //     if (!state.error) {
      //       state.wait = false;
      //       state.message = `Database restored with ${cardsCount} cards and ${conflictsCount} rejected due to conflicts, from [${bkpFile.name}] successfully`;
      //       dispatch('loadCards');
      //       state.status = true;
      //     }
      //   });
      //   reader.readAsText(bkpFile);
      // }
    },
    async destroyDB({ state }) {
      try {
        state.wait = true;
        await db.destroy();
        state.items = [];
        state.wait = false;
        state.message = "Items data cleared successfully."
        state.status = true;
        db = initDB();
      } catch (err) {
        state.wait = false;
        state.message = err;
        state.error = true;
      }
    }
  },
  modules: {
  }
})