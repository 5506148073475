<template lang="pug">
v-bottom-sheet(
  v-model="$store.state.cameraSheet",
  scrollable,
  persistent,
  inset
)
  v-card.rounded-lg
    v-toolbar(height="35", flat, dark, color="primary")
      v-toolbar-title {{ getTitle() }}
      v-spacer
      //- v-btn(icon, color="red", @click="swapCamera()")
      //-   v-icon mdi-camera-switch-outline
      v-btn(icon, @click="close()")
        v-icon mdi-close
    v-card-text.pa-0(v-show="tab == 0", align="center")
      //- span {{ debug }}
      video(
        ref="video",
        :controls="false",
        autoplay="true",
        @play="renderOnCanvas($event.srcElement)",
        v-show="false"
      ) Camera Stream
      canvas.pa-0.pt-1(
        v-show="true",
        :width="screen.width",
        :height="screen.width * aspect",
        ref="canvas"
      )
    v-overlay.pa-0(
      v-model="overlay",
      v-show="tab == 1 ",
      align="center",
      absolute
    )
      .pt-2
      span Pinch
      v-icon(color="secondary", x-large) mdi-gesture-pinch
      span to zoom-out,
      br
      span Spread
      v-icon(color="secondary", x-large) mdi-gesture-spread
      span to zoom-in
      br 
      span Slide
      v-icon(color="secondary", x-large) mdi-drag-variant
      span to align it to the center
      br
      br
      v-chip(@click="overlay = false", small, color="secondary") Alright!
    v-card-text.pa-0(v-show="tab==1", align="center")
      v-slider.pt-3.pl-4.pr-4(
        color="secondary",
        v-model="rotate",
        max="90",
        min="-90",
        dense,
        append-icon="mdi-rotate-left",
        prepend-icon="mdi-rotate-right",
        @click:append="rotateRight",
        @click:prepend="rotateLeft"
      )
      clipper-fixed.my-clipper.pa-0(
        ref="clipper",
        :src="dataUri",
        :ratio="parseFloat(aspect)",
        :area="100",
        bgColor="grey",
        :rotate="rotate"
      )

    v-card-actions(style="background-color: black") 
      .action-slots.pa-0.pt-1.pb-1
        .slot 
          v-btn(
            v-if="tab == 0",
            fab,
            dark,
            small,
            outlined,
            color="secondary",
            @click.stop="vibrate(); openGallery()"
          )
            v-icon mdi-folder-open
          input(
            v-show="false",
            type="file",
            ref="gallery",
            accept="image/*",
            v-on:change="slurpGalleryImage()"
          )
        .slot
        .slot 
          //- v-btn(
          //-   v-if="tab == 1",
          //-   @click="vibrate(); tab = 0",
          //-   icon,
          //-   small,
          //-   color="red"
          //- )
          //-   v-icon mdi-camera
        .slot 
          v-btn(
            v-if="tab == 0",
            @click="vibrate(); slurpImage()",
            x-large,
            icon,
            outlined,
            color="red"
          )
            v-icon mdi-checkbox-blank-circle
          v-btn(
            v-if="tab == 1",
            @click="vibrate(); emitData()",
            x-large,
            icon,
            outlined,
            :color="overlay ? 'black' : 'green'"
          )
            v-icon mdi-check
        .slot 
          v-btn(
            v-if="tab == 1",
            icon,
            small,
            :color="overlay ? 'black' : 'red'",
            @click="vibrate(); tab = 0"
          )
            v-icon mdi-close
        .slot
        .slot
          v-btn(
            v-if="!torchOn && tab == 0",
            icon,
            small,
            @click.stop="toggleTorch()"
          )
            v-icon(color="grey") mdi-flashlight-off
          v-btn(
            v-if="torchOn && tab == 0",
            icon,
            small,
            @click.stop="toggleTorch()"
          ) 
            v-icon(color="secondary") mdi-flashlight
</template>
<script>
import { clipperFixed } from "vuejs-clipper";
export default {
  name: "camera-sheet",
  components: { clipperFixed },
  props: { width: String, height: String, aspect: String },
  beforeMount() {
    this.openCamera();
    this.overlay = this.$store.state.items.length == 0 ? true : false;
  },
  data: () => ({
    tab: 0,
    rotate: 0,
    overlay: false,
    cameraObj: { name: "Front", code: "environment" },
    mediaStream: null,
    dataUri: null,
    torchOn: false,
    screen: screen,
    debug: null,
  }),
  methods: {
    getTitle() {
      let title;
      switch (this.tab) {
        case 0:
          title = "Step 1 of 2: Take a picture";
          break;
        case 1:
          title = "Step 2 of 2: Adjust and save";
          break;
        default:
          break;
      }
      return title;
    },
    rotateLeft() {
      this.rotate += 1;
    },
    rotateRight() {
      this.rotate -= 1;
    },
    renderOnCanvas(video) {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      this.debug = {
        vw: video.videoWidth,
        vh: video.videoHeight,
        cw: canvas.width,
        ch: canvas.height,
        sw: screen.width,
        sh: screen.height,
      };
      function step() {
        ctx.drawImage(
          video,
          video.videoWidth / 2 - canvas.width / 2,
          video.videoHeight / 2 - canvas.height / 2,
          canvas.width,
          canvas.height, //height
          0,
          0,
          canvas.width,
          canvas.height
        );
        requestAnimationFrame(step);
      }
      requestAnimationFrame(step);
    },
    slurpImage() {
      const canvas = this.$refs.canvas;
      this.dataUri = canvas.toDataURL("image/png"); // can also use 'image/png'
      if (this.torchOn) {
        this.toggleTorch();
      }
      this.flipImage();
      this.$forceUpdate();
    },
    clearImage() {
      this.tab = 0;
    },
    flipImage() {
      this.tab = 1;
      this.rotate = 0;
    },
    flipVideo() {
      this.tab = 0;
    },
    openGallery() {
      this.$refs.gallery.click();
    },
    async slurpGalleryImage() {
      const file = this.$refs.gallery.files[0];
      if (file) {
        const canvas = this.$refs.canvas;
        const img = new Image();
        canvas.height = this.$refs.video.videoHeight;
        canvas.width = this.$refs.video.videoWidth;
        img.onload = () => {
          this.fitImageOnCanvas(canvas, img);
          this.dataUri = canvas.toDataURL("image/png"); // can also use 'image/png'
          this.flipImage();
        };
        img.src = URL.createObjectURL(file);
      }
    },
    fitImageOnCanvas(canvas, imageObj) {
      const context = canvas.getContext("2d");
      const imageAspectRatio = imageObj.width / imageObj.height;
      const canvasAspectRatio = canvas.width / canvas.height;
      var renderableHeight, renderableWidth, xStart, yStart;

      // If image's aspect ratio is less than canvas's we fit on height
      // and place the image centrally along width
      if (imageAspectRatio < canvasAspectRatio) {
        renderableHeight = canvas.height;
        renderableWidth = imageObj.width * (renderableHeight / imageObj.height);
        xStart = (canvas.width - renderableWidth) / 2;
        yStart = 0;
      }

      // If image's aspect ratio is greater than canvas's we fit on width
      // and place the image centrally along height
      else if (imageAspectRatio > canvasAspectRatio) {
        renderableWidth = canvas.width;
        renderableHeight = imageObj.height * (renderableWidth / imageObj.width);
        xStart = 0;
        yStart = (canvas.height - renderableHeight) / 2;
      }

      // Happy path - keep aspect ratio
      else {
        renderableHeight = canvas.height;
        renderableWidth = canvas.width;
        xStart = 0;
        yStart = 0;
      }
      context.drawImage(
        imageObj,
        xStart,
        yStart,
        renderableWidth,
        renderableHeight
      );
    },
    openCamera() {
      const mediaConstraints = {
        audio: false,
        video: {
          facingMode: this.cameraObj.code,
          width: this.width,
          height: this.width,
          aspectRatio: { ideal: 1 / 1 },
        },
      };
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia(mediaConstraints)
          .then((stream) => {
            // alert("obtained video stream");
            this.tab = 0;
            this.mediaStream = stream;
            this.$refs.video.srcObject = stream;
            this.$store.state.dbgdmp.cam.ok = 1;
            this.$store.state.dbgdmp.dvc.scrw = screen.width;
            this.$store.state.dbgdmp.dvc.scrh = screen.height;
          })
          .catch((err) => {
            const errmsg =
              "Error initializing camera. Please check permission and grant if deneid." +
              JSON.stringify(err);
            this.$store.state.dbgdmp.cam.err += errmsg;
            this.$store.state.cameraSheet && alert(errmsg);
            this.closeCamera();
          });
      }
    },
    async toggleTorch() {
      this.torchOn = !this.torchOn;
      if (this.mediaStream) {
        try {
          const track = this.mediaStream.getVideoTracks()[0];
          await track.applyConstraints({
            advanced: [{ torch: this.torchOn }],
          });
        } catch (error) {
          this.torchOn = false;
          alert("Starting torch failed.\nError: " + error.message);
        }
      }
    },
    swapCamera() {
      const camera = this.cameraObj;
      if (camera.code == "user") {
        camera.name = "Back Camera";
        camera.code = "environment";
      } else {
        camera.name = "Front Camera";
        camera.code = "user";
      }
      this.openCamera();
    },
    closeCamera() {
      if (this.mediaStream) {
        const track = this.mediaStream.getVideoTracks()[0];
        track && track.stop();
      }
    },
    close() {
      this.closeCamera();
      this.$store.state.cameraSheet = false;
    },
    emitData() {
      const canvas = this.$refs.clipper.clip();
      this.dataUri = canvas.toDataURL("image/jpeg", 1);
      this.$emit("data", this.dataUri);
      if (this.$store.state.continueCameraAfterSave) {
        this.flipVideo();
      } else {
        this.close();
      }
    },
  },
};
</script>
<style scoped>
.my-clipper {
  width: 100%;
  max-width: 100%;
}
.action-slots {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1fr 1fr;
  background-color: black;
  width: 100%;
}
.slot {
  display: grid;
  align-items: center;
  justify-items: center;
}
</style> 