<template lang="pug">
v-banner.animated.slideInUp
  v-toolbar(flat) 
    v-spacer

  v-sheet.pa-0.pb-1.d-flex.justify-space-around(align="stretch")
    v-btn(
      fab,
      color="green",
      :disabled="!phone",
      :href="'sms:' + phone + '?body=Hi'",
      target="_blank",
      :dark="!!phone",
      @click="act('txt')"
    )
      v-icon(large) mdi-message-arrow-right
    v-btn.ml-1(
      fab,
      color="green",
      :disabled="!phone",
      :href="'https://wa.me/' + phone + '/?text=%0A%0A%0ASent%20through%20EzHolder%20for%20business%20cards%20app'",
      target="_blank",
      :dark="!!phone",
      @click="act('whp')"
    )
      v-icon(large) mdi-whatsapp
    v-btn.ml-1(
      fab,
      color="green",
      :disabled="!phone",
      :href="'tel:' + phone",
      target="_blank",
      :dark="!!phone",
      @click="act('phn')"
    )
      v-icon(large) mdi-phone-forward
    v-btn.ml-1(
      fab,
      color="teal",
      :disabled="!email",
      :href="'mailto:' + email + '?subject=Hello&body=Hi there%2C%0A%0A%3Cplease enter your message%3E%0A%0ASent%20through%20EzHolder%20for%20business%20cards%20app'",
      target="_blank",
      :dark="!!email",
      @click="act('eml')"
    )
      v-icon(large) mdi-email-send
    v-btn.ml-1(
      fab,
      color="blue",
      v-show="!!link",
      :href="'http://' + link",
      target="_blank",
      :dark="!!link",
      @click="act('lnk')"
    )
      v-icon(large) mdi-earth-arrow-right
    v-btn.ma-1(
      fab,
      color="blue",
      v-if="!phone || !email || !link",
      :href="'https://www.google.com/search?q='",
      target="_blank",
      dark,
      @click="act('src')"
    )
      v-icon(large) mdi-search-web
</template>
<script>
export default {
  data: () => ({}),
  computed: {
    phone() {
      return "";
    },
    email() {
      return "";
    },
    link() {
      return "";
    },
  },
  methods: {
    isEppPhone() {
      return true;
    },
    isEmail() {
      return true;
    },
    isUrl() {
      return true;
    },
    act(actType) {
      //"phn, eml,whp, txt, lnk, src"
      this.$store.state.dbgdmp.act[actType] = 1;
    },
  },
};
</script>
