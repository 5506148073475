<template lang="pug">
v-dialog.record(v-model="$store.state.itemDialog", fullscreen, persistent)
  v-card.ritem
    v-toolbar.rounded-b-lg(
      dense,
      dark,
      color="primary",
      max-height="50",
      elevation="0"
    )
      v-btn(icon, large, @click="goBack()")
        v-icon mdi-arrow-left
      v-toolbar-title(color="primary") Item Actions
    v-card-text.rtext.pa-0 
      editor(
        v-show="panel==0",
        :canvasWidth="Math.min(512, screen.width)",
        :canvasHeight="Math.min(512, screen.width)",
        ref="bodyeditor",
        editorId="bodyImage"
      ) 
      editor(
        v-show="panel==1",
        :canvasWidth="Math.min(512, screen.width)",
        :canvasHeight="Math.min(512, screen.width)",
        ref="gilleditor",
        editorId="gillImage"
      ) 
      editor(
        v-show="panel==2",
        :canvasWidth="Math.min(512, screen.width)",
        :canvasHeight="Math.min(512, screen.width)",
        ref="eyeeditor",
        editorId="eyeImage"
      )
      v-btn.mt-12.mr-n2(
        v-if="panel != 0",
        fab,
        fixed,
        right,
        top,
        color="secondary",
        @click="launchCamera()"
      )
        v-icon(color="black") mdi-camera-plus-outline
      v-sheet.scrollable.pl-2.pr-2.pt-0(
        align="center",
        color="grey lighten-3",
        height="100%"
      ) 
        .pt-1.pb-1(align="center")
          v-btn-toggle(v-model="panel", color="secondary", rounded, small)
            v-btn(small) 
              v-icon(color="black") mdi-fish
              span Body >
            v-btn(small) 
              v-icon(color="black") mdi-tailwind
              span Gill >
            v-btn(small) 
              v-icon(color="black") mdi-eye
              span Eye []

      v-sheet.pr-4.pl-4.pt-4(align="center", elevation=0)
        v-btn(v-if="selectedItem.freshness == 0", x-large, elevation=0, block)
          span.pr-3 STALE
          v-icon.pl-1(color="red", x-large, right) mdi-thumb-down
        v-btn(v-if="selectedItem.freshness == 1", x-large, elevation=0, block) 
          span.pr-3 MODERATE
          v-icon.pl-1(
            color="orange",
            x-large,
            right,
            style="transform: rotate(-270deg)"
          ) mdi-thumb-down
        v-btn(
          v-if="selectedItem.freshness == 2",
          x-large,
          tile,
          elevation=0,
          block
        )
          span.pr-3 FRESH
          v-icon(
            x-large,
            color="green",
            right,
            style="transform: rotateX(180deg)"
          ) mdi-thumb-down
    v-divider 
    v-card-actions.raction.pr-2.pl-2(
      v-if="$store.state.selectedItem",
      elevation="2"
    )
      v-chip(color="secondary", @click="goBack()", small)
        v-icon(left) mdi-arrow-left
        span BACK
      v-spacer
      v-chip(color="secondary", @click="vibrate(); deleteItem()", small)
        v-icon(left) mdi-delete-outline
        span DELETE
      v-spacer
      v-progress-circular(color="primary", indeterminate, v-if="inspecting")
      v-chip(
        small,
        color="grey",
        @click.stop="cancelInspect()",
        v-if="inspecting"
      ) 
        v-icon(left, small) mdi-close
        span CANCEL INSPECT
      v-chip(
        small,
        color="secondary",
        @click.stop="inspectItem()",
        v-if="!inspecting"
      ) 
        v-icon(left, small) mdi-layers-search
        span RE-INSPECT
      v-spacer
      v-chip(color="secondary", @click="vibrate(); saveItem()", small)
        v-icon(left) mdi-check
        span SAVE
      confirm-dialog(ref="confirm")
</template>
<script>
import { clipperBasic } from "vuejs-clipper";
import Editor from "vue-image-markup";
import { VImg } from "vuetify/lib";

import ConfirmDialog from "./Confirm.vue";
import ActionsBanner from "../banners/Actions.vue";

export default {
  name: "item",
  components: { ConfirmDialog, clipperBasic, VImg, ActionsBanner, Editor },
  async beforeMount() {
    await this.initImages();
    this.render("body");
  },
  data: () => ({
    screen: screen,
    scaleFactor: 512 / Math.min(512, screen.width),
    panel: 0,
    noTextAlert:
      "No text found. Please click on CLIP BLOCK button to extract or EDIT TEXT button to enter text.",
    fab: true,
    originalText: "",
    changedText: "",
    bodyUrl: "",
    gillUrl: "",
    eyeUrl: "",
    rules: {
      email: (v) => !!(v || "").match(/@/) || "Please enter a valid email",
      max: (len) => (v) =>
        (v || "").length <= len || `Text length exceeded, allowed max ${len}`,
      password: (v) =>
        !!(v || "").match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
        ) ||
        "Password must contain an upper case letter, a numeric character, and a special character",
      required: (v) => !!v || "This field is required",
    },
  }),
  computed: {
    selectedItem() {
      return this.$store.state.selectedItem;
    },
    inspecting() {
      return (
        this.$store.state.selectedItem &&
        this.$store.state.selectedItem.inspecting
      );
    },
  },
  methods: {
    launchCamera() {
      switch (this.panel) {
        case 0:
          this.$store.state.cameraPicType = "body";
          break;
        case 1:
          this.$store.state.cameraPicType = "gill";
          break;
        case 2:
          this.$store.state.cameraPicType = "eye";
          break;
        default:
          break;
      }
      this.$store.state.cameraSheet = true;
    },
    async initImages() {
      const item = this.$store.state.selectedItem;
      if (item && item["_attachments"]) {
        let bodyImagedata = item._attachments.bodyImage.data;
        if (!bodyImagedata) {
          const bodyImageBlob = await this.$store.dispatch("slurpAttachment", {
            item: item,
            image: "bodyImage",
          });
          item._attachments.bodyImage.data = bodyImageBlob;
          bodyImagedata = bodyImageBlob || this.getDummyImageBlob();
        }
        this.bodyUrl = this.createObjectURL(bodyImagedata);
        //-
        let gillImagedata = item._attachments.gillImage.data;
        if (!gillImagedata) {
          const gillImageBlob = await this.$store.dispatch("slurpAttachment", {
            item: item,
            image: "gillImage",
          });
          item._attachments.gillImage.data = gillImageBlob;
          gillImagedata = gillImageBlob || this.getDummyImageBlob();
        }
        this.gillUrl = this.createObjectURL(gillImagedata);
        //-
        let eyeImagedata = item._attachments.eyeImage.data;
        if (!eyeImagedata) {
          const eyeImageBlob = await this.$store.dispatch("slurpAttachment", {
            item: item,
            image: "eyeImage",
          });
          item._attachments.eyeImage.data = eyeImageBlob;
          eyeImagedata = eyeImageBlob || this.getDummyImageBlob();
        }
        this.eyeUrl = this.createObjectURL(eyeImagedata);
      }
    },
    getHighlightClass() {
      const idx = -1;
      return idx != -1 ? "highlight" : "";
    },
    goBack() {
      this.$store.state.itemDialog = false;
    },
    inspectItem() {
      this.$store.state.selectedItem.inspecting = true;
      setTimeout(() => {
        this.$store.state.selectedItem.freshness = Math.floor(
          Math.random() * 3
        );
        this.$store.state.selectedItem.inspecting = false;
        this.$store.dispatch("saveItem", this.$store.state.selectedItem);
      }, 1000);
    },
    cancelInspect() {
      this.$store.state.selectedItem.inspecting = false;
      this.$store.dispatch("saveItem", this.$store.state.selectedItem);
    },
    saveItem() {
      const itemToSave = this.selectedItem;
      ["body", "gill", "eye"].forEach((ed) => {
        let rect = this.$refs[ed + "editor"].canvas.getObjects();
        if (rect.length == 1) {
          const newRect = this.getDefaultRect();
          newRect.width = rect[0].aCoords.tr.x - rect[0].aCoords.tl.x;
          newRect.height = rect[0].aCoords.bl.y - rect[0].aCoords.tl.y;
          newRect.left = rect[0].aCoords.tl.x;
          newRect.top = rect[0].aCoords.tl.y;
          newRect.angle = rect[0]["angle"] || 0;
          itemToSave[ed + "Rect"] = newRect;
        }
      });
      this.$store.dispatch("saveItem", itemToSave);
    },
    async deleteItem() {
      if (
        await this.$refs.confirm.open("Delete", "Are you sure?", {
          color: "primary",
        })
      ) {
        this.$store.dispatch("removeItem", this.$store.state.selectedItem);
        this.goBack();
      }
    },
    render(ed) {
      const editor = this.$refs[ed + "editor"];
      const url = this[ed + "Url"];
      let itemRect = this.selectedItem[ed + "Rect"] || this.getDefaultRect();
      itemRect = JSON.parse(JSON.stringify(itemRect));
      //adjust scaling factor
      const multiplier = itemRect.scaleFactor / this.scaleFactor;
      itemRect.width = itemRect.width * multiplier;
      itemRect.height = itemRect.height * multiplier;
      itemRect.left = itemRect.left * multiplier;
      itemRect.top = itemRect.top * multiplier;
      //>>
      if (url) {
        editor.setBackgroundImage(url);
        let rect = editor.canvas.getObjects();
        if (rect.length == 0) {
          editor.drawRect(itemRect);
          // editor.set("rect", itemRect);
          rect = editor.canvas.getObjects();
          rect[0].angle = itemRect.angle;
        }
        editor.set("selectMode");
      }
    },
  },
  watch: {
    selectedItem(newItem, oldItem) {
      //to handle when a new item is added for the first time
      if ((newItem && !oldItem) || newItem._id != oldItem._id) {
        this.bodyUrl = this.createObjectURL(
          newItem._attachments.bodyImage.data
        );
      }
    },
    panel(nVal) {
      if (nVal >= 0) {
        this.render(["body", "gill", "eye"][nVal]);
      } else {
        this.panel = 0;
      }
    },
  },
};
</script>
<style scoped>
.scrollable {
  overflow-x: auto;
  overflow-y: auto;
}
.highlight {
  background-color: #ffc246;
  border: solid 0px;
}
.box {
  border: solid 2px;
  border-color: red;
}
.record {
  display: flex;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 640px;
}
.ritem {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
}
.rtitle,
.raction {
  flex: 0 0 auto;
}
.rtext {
  backface-visibility: hidden;
  flex: 1 1 auto;
  overflow-y: auto;
}
.my-clipper {
  width: 100%;
  max-width: 100%;
}
</style> 