import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueRx from 'vue-rx';
import VuejsClipper from 'vuejs-clipper';
import VuePressDirective from 'vue-press-directive';
import { globalMixin } from './mixins/globalMixin.js';

Vue.config.productionTip = false

// install vue-rx
Vue.use(VueRx)
// install vuejs-clipper
Vue.use(VuejsClipper)
Vue.use(VuePressDirective, { interval: 600 })
Vue.mixin(globalMixin);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
