<template lang="pug">
v-lazy(
  min-height="90",
  v-ripple,
  :elevation="item._id === ($store.state.selectedItem && $store.state.selectedItem._id) ? '10' : '1'",
  v-model="loaded",
  :options="{ threshold: 0.5 }",
  height="100%"
)
  v-sheet(v-press="longPressed", @click="clicked($event)") 
    v-img.rounded.box.animated.zoomIn(
      ref="img",
      :src="bodyImage",
      lazy-src="/applogo_small.png",
      @click="$emit('click')",
      width="100%",
      :class="[highlight, longhighlight]"
    ) 
      v-icon.pl-1(v-if="longhighlight", color="primary") mdi-check-circle
      v-icon.pl-1(v-if="false && !item.text", color="error", small) mdi-alert
      v-icon.pl-1(color="red", large, v-if="item.freshness == 0") mdi-thumb-down
      v-icon.pl-1(
        color="orange",
        large,
        v-if="item.freshness == 1",
        style="transform: rotate(-270deg)"
      ) mdi-thumb-down
      v-icon.pl-1(
        large,
        color="green",
        v-if="item.freshness == 2",
        style="transform: rotateX(180deg)"
      ) mdi-thumb-down
      v-icon.pl-1.pt-1(
        v-if="item.isPinned",
        style="transform: rotate(-45deg)",
        color="secondary"
      ) mdi-pin

      v-overlay(v-if="item.inspecting", absolute)
        v-progress-circular(
          color="secondary",
          indeterminate,
          rounded,
          height="6"
        )
        small Inspecting item..
</template>
<script>
export default {
  name: "lazyimg",
  props: { item: Object },
  data: () => ({
    loaded: false,
    bodyImage: "",
  }),
  computed: {
    highlight() {
      const highlight =
        this.item._id ===
        (this.$store.state.selectedItem && this.$store.state.selectedItem._id);
      return highlight ? "highlight" : "";
    },
    longhighlight() {
      const highlight = this.$store.state.selectedItemsGroup
        .map((c) => c._id)
        .includes(this.item._id);
      return highlight ? "longhighlight" : "";
    },
  },
  methods: {
    longPressed() {
      const idx = this.$store.state.selectedItemsGroup.findIndex(
        (c) => c._id == this.item._id
      );
      if (idx == -1) {
        this.$store.state.selectedItemsGroup.push(this.item);
        this.$store.state.selectedItem = null;
      } else {
        this.$store.state.selectedItemsGroup.splice(idx, 1);
      }
    },
    clicked(event) {
      if (this.$store.state.selectedItemsGroup.length) {
        const idx = this.$store.state.selectedItemsGroup.findIndex(
          (c) => c._id == this.item._id
        );
        if (idx == -1) {
          this.$store.state.selectedItemsGroup.push(this.item);
        } else {
          this.$store.state.selectedItemsGroup.splice(idx, 1);
        }
      } else {
        this.$emit("view", event);
      }
    },
  },
  watch: {
    async loaded(newVal, oldVal) {
      if (newVal && !oldVal) {
        if (!this.item._attachments.bodyImage.data) {
          const bodyImageBlob = await this.$store.dispatch("slurpAttachment", {
            item: this.item,
            image: "bodyImage",
          });
          this.item._attachments.bodyImage.data = bodyImageBlob;
        }
        this.bodyImage = this.createObjectURL(
          this.item._attachments.bodyImage.data
        );
      }
    },
  },
};
</script>
<style>
.highlight {
  border: solid 4px;
  border-color: #ff9100;
}
.longhighlight {
  border: solid 8px;
  /* border-color: #4f83cc; */
  /*border-color: #01579b;*/
  border-color: lightsteelblue;
}
</style>
